<template>
  <v-expansion-panel-content v-model="expandedValue" :readonly="readonly">
    <div slot="header" :data-cy="`${sensor.name}-panel`">
      <v-layout>
        <v-flex xs1 class="mr-1">
          <v-tooltip top color="sdark" :disabled="!activeDroneOptID(sensor)">
            <v-icon
              :data-testid="`sensor-led-${sensor.serial_number}`"
              slot="activator"
              small
              :color="getSensorColor(sensor, sensorType)"
              v-if="!activePlan"
              :class="activeDroneOptID(sensor) ? 'droneoptid' : ''"
            >fiber_manual_record
            </v-icon>
            <span>Active DroneOptID</span>
          </v-tooltip>
        </v-flex>
        <v-flex data-testid="sensor-name">
          {{ sensor.name || sensor.type.slice(0, -1) }}
        </v-flex>
      </v-layout>
    </div>
    <v-card>
      <!-- GENERAL EDIT ACTIONS -->
      <v-card-actions v-if="editMode">
        <!-- EDIT SENSOR BUTTON -->
        <v-tooltip bottom>
          <v-btn
            :data-cy="`edit-${sensor.name}`"
            slot="activator"
            color="primary"
            icon
            flat
            @click="onClickEdit(sensorType, sensor)"
          >
            <v-icon>edit</v-icon>
          </v-btn>
          <span>Edit Sensor</span>
        </v-tooltip>
        <!-- RF FILTERS EDIT -->
        <v-tooltip bottom>
          <v-btn
            slot="activator"
            v-if="
              ['rf_sensors', 'dsx_sensors'].includes(sensorType) && !activePlan
            "
            @click="onClickFilters(sensor)"
            color="primary"
            icon
            flat
          >
            <v-icon>filter_list</v-icon>
          </v-btn>
          <span>RF Filters</span>
        </v-tooltip>

        <!-- Radar Zone Mask EDIT -->
        <v-tooltip bottom>
          <v-btn
            slot="activator"
            v-if="['radars'].includes(sensorType) && !activePlan"
            @click="onClickMasks(sensor)"
            color="primary"
            icon
            flat
          >
            <v-icon>track_changes</v-icon>
          </v-btn>
          <span>Add Radar Mask</span>
        </v-tooltip>

        <!-- Camera Calibration -->

        <v-tooltip bottom v-if="['cameras'].includes(sensorType) && !activePlan">
          <v-btn
            slot="activator"
            
            @click="onClickDroneCalibration(sensor)"
            color="primary"
            icon
            flat
          >
            <v-icon>filter_tilt_shift</v-icon>
          </v-btn>
          <span>Drone Calibration</span>
        </v-tooltip>

        <v-tooltip bottom v-if="['cameras'].includes(sensorType) && !activePlan && isAdmin">
          <v-btn
            slot="activator"
            
            @click="onClickBracketCalibration(sensor)"
            color="primary"
            icon
            flat
          >
            <v-icon>filter_center_focus</v-icon>
          </v-btn>
          <span>Bracket Calibration</span>
        </v-tooltip>

        <!-- ACCESS SENSOR WEBSERVER -->
        <v-tooltip bottom>
          <v-btn
            slot="activator"
            v-if="
               isAdmin && smarthubIP &&
                ['rf_sensors','dsx_sensors'].includes(sensorType) &&
                !activePlan
            "
            :href="`${smarthubIP}/sensor/${sensor.serial_number}`"
            target="_blank"
            color="primary"
            icon
            flat
          >
            <v-icon>web</v-icon>
          </v-btn>
          <span>Access Device Manager GUI</span>
        </v-tooltip>

        <!-- DELETE SENSOR BUTTON -->
        <v-tooltip bottom>
          <v-btn
            :data-cy="`delete-${sensor.name}`"
            slot="activator"
            color="primary"
            icon
            flat
            @click="onClickDelete(sensorType, sensor)"
          >
            <v-icon>delete</v-icon>
          </v-btn>
          <span>Delete Sensor</span>
        </v-tooltip>
      </v-card-actions>
      <!-- COMMON DETAILS -->
      <v-card-text
        v-if="
          [
            'radars',
            'cannons',
            'rf_sensors',
            'dsx_sensors',
            'cameras'
          ].includes(sensorType) && !activePlan
        "
        class="py-0"
      >
        <v-layout align-center justify-center row>
          <v-flex v-if="showEnableToggle">
            <!-- ENABLE SWITCH -->
            <div class="pa-1">
              <v-switch
                :data-testid="`sensor-enable-switch-${sensor.serial_number}`"
                v-show="isAuthorized('operator')"
                @click.native.prevent.stop.capture="
                  onSensorStatusChange($event, sensorType, sensor)
                "
                :disabled="disableEnableToggle"
                :loading="loadingEnableToggle"
                hide-details
                color="success"
                :label="labelEnableToggle"
                :input-value="inputValueEnableToggle"
              />
            </div>
          </v-flex>
        </v-layout>
        <v-alert
          outline
          :value="sensorScopedError || sensor.error || scopedRadarError"
          color="primary"
        >{{ sensorScopedError || sensor.error || scopedRadarError}}
        </v-alert
        >
      </v-card-text>
      <template v-if="!activePlan">
        <v-card-text
          v-if="
            [
              'radars',
              'rf_sensors',
              'gps_compasses',
              'dsx_sensors',
              'cameras'
            ].includes(sensorType)
          "
        >
          <p
            v-if="['radars'].includes(sensorType)"
            class="mb-0"
            data-testid="sensor-channel"
          >
            Channel: {{ sensor.channel + 1 }}
          </p>
          <p
            v-if="['radars'].includes(sensorType)"
            class="mb-0"
            data-testid="sensor-channel"
          >
            {{ `TCM State: ${tcm_state}` }}
          </p>
          <p class="mb-0" data-testid="sensor-serial">
            Serial: {{ sensor.serial_number }}
          </p>
          <p
            class="mb-0"
            v-if="
              ['rf_sensors', 'dsx_sensors', 'cameras'].includes(sensorType) &&
                sensor.software_version
            "
            data-testid="sensor-sw-version"
          >
            Software Version: {{ sensor.software_version }}
          </p>
          <p
            class="mb-0"
            v-if="'radars' === sensorType && sensor.fw_version"
            data-testid="sensor-fw-version"
          >
            Software Version: {{ sensor.fw_version }}
          </p>
          <span
            v-if="sensorType !== 'cameras' && sensor.status_message"
            class="mb-0"
            data-testid="sensor-status"
          >Status:
            {{
              radarStatusMatch ? sensor.status_message : radarLoadingMessage
            }}</span
          >
          <p
            class="mb-0"
            v-if="sensorType !== 'cameras' && sensor.model !== 'rf_patrol'"
            data-testid="sensor-direction"
          >
            Direction:
            <DegreeDisplay :degree="sensor.direction.toFixed(2)" />
          </p>
          <p
            class="mb-0"
            v-if="
              sensor.model === 'rf_two' &&
                sensor.tilt
            "
          >
            Tilt:
            <DegreeDisplay :degree="sensor.tilt.toFixed(2)" />
          </p>
          <span
            class="mb-0"
            v-if="
              sensor.status === 'transmitting' &&
                sensor.meta &&
                sensor.meta.search_time
            "
          >
            (Scan Rate: {{ sensor.meta.search_time }}s)
          </span>
          <p
            data-testid="sensor-radar-masks-active-p"
            class="mb-0"
            v-if="sensorType === 'radars'"
          >
            Zone Masks Active: {{ radarMasksActive }}
          </p>
          <p
            data-testid="sensor-active-filter-count-p"
            class="mb-0"
            v-if="['rf_sensors', 'dsx_sensors'].includes(sensorType)"
          >
            Active Filters: {{ rfActiveFilterCountBySensorId(sensor.id) }}
          </p>
          <p
            data-testid="sensor-agl-masks-active-p"
            class="mb-0"
            v-if="sensorType === 'radars'"
          >
            AGL Active Zone: {{ aglMaskActive }}
          </p>
          <p
            class="mb-0"
            v-if="activeDroneOptID(sensor) && droneoptidTemperature"
            data-testid="camera-temp"
          >
            DroneOptID Temperature: {{ droneoptidTemperature() }}°C
          </p>
        </v-card-text>
        <v-card-text v-if="['discovair_sensors'].includes(sensorType)">
          <span data-testid="sensor-serial"
          >Serial: {{ sensor.serial_number }}</span
          >
          <br />
          <span>Detector ID: {{ sensor.detector_identifier }}</span>
          <br />
          <span>{{ sensor.status_message }}</span>
        </v-card-text>

        <!-- CANNONS DETAILS -->
        <div v-if="sensorType === 'cannons'">
          <v-card-text>
            <v-layout align-center justify-center row>
              <v-flex xs6>
                <div
                  v-if="
                    sensor.power_trigger_engaged && sensor.auto_jam_countdown
                  "
                  class="text-xs-center"
                >
                  <v-progress-circular
                    :size="70"
                    :width="3"
                    :value="progressCannon"
                    color="red"
                    rotate="-90"
                  >{{ sensor.auto_jam_countdown }}s
                  </v-progress-circular
                  >
                </div>
              </v-flex>
            </v-layout>
            <v-flex xs12>
              <span data-testid="sensor-serial"
              >Serial: {{ sensor.serial_number }}</span
              >
              <br />
              <span>Status: {{ sensor.status_message }}</span>
            </v-flex>
          </v-card-text>
        </div>
        <v-card
          flat
          :key="`band-card-sentry-${sensor.id}-${sensorType}`"
          v-if="['cannons', 'dsx_sensors'].includes(sensorType)
            && !activePlan"
        >
          <BandControl
            :type="sensorType"
            :editMode="editMode"
            :disabled="editMode && isAuthorized('site_manager')"
            :deviceStatus="sensorType === 'cannons' ? this.$_cannonSensorStatus : this.$_dsxSensorStatus"
            :isConfigureAllBands="isConfigureAllBands"
            :device="sensor"
            :showBandStatusDots="isConfigureAllBands"
          />
        </v-card>
      </template>
    </v-card>
  </v-expansion-panel-content>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { SensorMixin } from '@/components/Mixins'
import { isDsxModelJamming } from '@/store/modules/rf_sensors/types'
import DegreeDisplay from '@/components/Util/DegreeDisplay.vue'
import { NO_TRACKING_TARGET_ID } from '@/store/modules/detection/types'
import BandControl from './BandControl.vue'

import capitalize from 'lodash/capitalize'

const props = {
  sensor: {
    type: Object
  },
  sentry: {
    type: Object
  },
  sensorType: {
    type: String,
    validator: val =>
      [
        'radars',
        'rf_sensors',
        'cannons',
        'gps_compasses',
        'cameras',
        'discovair_sensors',
        'dsx_sensors'
      ].includes(val)
  },
  cannonsDisabled: {
    type: Boolean
  },
  editMode: {
    type: Boolean,
    default: false
  },
  readonly: {
    type: Boolean,
    default: false
  },
  isConfigureAllBands: {
    type: Boolean,
    default: false
  }
}

export default {
  name: 'SensorContent',
  mixins: [SensorMixin],
  components: {
    BandControl,
    DegreeDisplay
  },
  props,
  data: () => ({
    expanded: false,
    is: false,
    jammerLoading: false,
    conversion: {
      radars: 'radar',
      rf_sensors: 'rf_sensor',
      cannons: 'cannon',
      gps_compasses: 'gps_compass',
      discovair_sensors: 'discovair_sensor',
      dsx_sensors: 'dsx_sensors'
    },
    longRadarLoadingInterval: null,
    radarLoadingMessage: 'Loading...',
    waitForQuery: false,
    isScanning: false,
    detectionType: ''
  }),
  computed: {
    ...mapGetters('sites', ['activeSiteId']),
    ...mapGetters('users', ['isAuthorized', 'isAdmin']),
    ...mapGetters('plans', ['activePlan']),
    ...mapState('sensors', ['activeKey', 'status', 'scopedError']),
    ...mapState('radars', ['errorByRadar']),
    ...mapGetters('radar_mask_zones', ['allRadarMasks']),
    ...mapGetters('agl_mask', ['aglMasks']),
    ...mapState('system', ['environment']),
    ...mapGetters('sites', { site: 'activeSite' }),
    ...mapState('selection', ['activeSensors', 'activeCamera']),
    ...mapGetters('rf_filters', ['rfActiveFilterCount']),
    ...mapGetters('sensors', ['smarthubIP']),
    isPlanningTool() {
      return this.$route.name === 'Plans'
    },
    expandedValue: {
      get() {
        return this.expanded
      },
      set(val) {
        this.expanded = val
        if (val) {
          this.activateSensor({
            sentry: this.sentry.id,
            sensor: this.sensor.id,
            type: this.sensorType
          })
        } else {
          this.selectSentry(null)
          this.deactivateSensor({
            sentry: this.sentry.id,
            sensor: this.sensor.id,
            type: this.sensorType
          })
        }
      }
    },
    tcm_state() {
      return this.sensor.meta && this.sensor.meta.tcm_state ? capitalize(this.sensor.meta.tcm_state) : 'Unknown'
    },
    isJammingDsx() {
      return isDsxModelJamming(this.sensor.model || '')
    },
    jammerActive() {
      return this.sensor.power_trigger_engaged || this.sensor.jamming
    },
    progressCannon() {
      const {
        sensor: { shutoff_time = 0, auto_jam_countdown = 0 }
      } = this
      return shutoff_time !== 0 && auto_jam_countdown !== 0
        ? parseInt(((shutoff_time - auto_jam_countdown) / shutoff_time) * 100)
        : 0
    },
    activeDroneOptID() {
      return sensor => {
        return sensor.status_color === 'green' && sensor.droneoptid
      }
    },
    radarStatusMatch() {
      if (!this.sensor.radar_type || this.sensor.status_color !== 'green')
        return true
      let { status, status_requested, status_requested_at } = this.sensor || {}
      const olderThanAMinute =
        Date.now() - new Date(status_requested_at).getTime() > 60000
      if (status_requested === 'standby') status_requested = 'idle'
      return olderThanAMinute || [null, status].includes(status_requested)
    },
    isSensorActive() {
      return !!this.activeSensors.find(({ sentry, sensor, type }) => {
        return (
          sentry === this.sentry.id &&
          sensor === this.sensor.id &&
          this.sensorType === type
        )
      })
    },
    showEnableToggle() {
      if (!this.editMode) {
        return false
      }

      return (
        (this.sensorType === 'radars' &&
          this.sensor.radar_type === 'radar_zero') ||
        this.sensorType === 'rf_sensors' ||
        this.sensorType === 'dsx_sensors'
      )
    },
    disableEnableToggle() {
      return (
        (this.sensorType === 'radars' && !this.radarStatusMatch) ||
        this.waitForQuery ||
        (!['green', 'grey'].includes(this.sensor.status_color) && this.sensor.state !== 'fault')
      )
    },
    loadingEnableToggle() {
      if (this.sensorType === 'radars') {
        return !this.radarStatusMatch && 'primary'
      } else {
        return this.waitForQuery
      }
    },
    labelEnableToggle() {
      return (this.sensorType === 'radars' &&
        this.sensor.status === 'transmitting') ||
      this.sensor.active
        ? 'Disable Detections'
        : 'Enable Detections'
    },
    inputValueEnableToggle() {
      return (
        (this.sensorType === 'radars' &&
          this.sensor.status === 'transmitting') ||
        this.sensor.active
      )
    },
    droneoptidTemperature() {
      let temperature = null
      return () => {
        temperature = this.sensor.droneoptid_temperature || temperature
        this.$bus.$emit('jetsonTemperature', temperature)
        return temperature
      }
    },
    scopedRadarError() {
      if (this.sensorType === "radars" && this.errorByRadar && this.errorByRadar[this.sensor.id]) {
        return this.errorByRadar[this.sensor.id]
      }

      return ""
    },
    sensorScopedError() {
      return (
        this.status === 'FAILED' &&
        (this.scopedError?.[this.sensorType]?.[this.sensor.id] || this.scopedRadarError())
      )
    },
    activeCameraId() {
      return this.activeCamera?.id
    },
    radarMasksActive() {
      let count = 0
      if (this.allRadarMasks[this.sensor.id]) {
        this.allRadarMasks[this.sensor.id].filter(m => {
          if (m.active === true) {
            count++
          }
        })
      }
      return count
    },
    aglMaskActive() {
      if (this.aglMasks[this.sensor.id]) {
        let maskEnabled = this.aglMasks[this.sensor.id].enabled
        return maskEnabled ? 'Enabled' : 'Not Enabled'
      }
      return 'No AGL Mask Set'
    },
    radarCameraTracking() {
      if (this.sensorType !== 'cameras') return false
      return (
        this.sensor?.radar_tracked_target != null &&
        this.sensor?.radar_tracked_target !== 0
      )
    },
    rfCameraTracking() {
      if (this.sensorType !== 'cameras') return false
      return (
        this.sensor?.rf_tracked_target != null &&
        this.sensor?.rf_tracked_target !== ''
      )
    },
    sfCameraTracking() {
      if (this.sensorType !== 'cameras') return false
      return (
        this.sensor?.tracked_target_id != null &&
        this.sensor?.tracked_target_id !== NO_TRACKING_TARGET_ID
      )
    },
    tracking() {
      return (
        this.radarCameraTracking ||
        this.rfCameraTracking ||
        this.sfCameraTracking
      )
    }
  },
  methods: {
    ...mapActions('radars', ['TOGGLE_RADAR']),
    ...mapActions('rf_sensors', ['TOGGLE_RF_SENSOR']),
    ...mapActions('cameras', [
      'CAMERA_CONTROL',
      'CAMERA_TRACK_FUSION',
      'STOP_CAMERA_TRACK_FUSION'
    ]),
    ...mapActions('selection', ['activateSensor', 'deactivateSensor']),
    ...mapActions('sentries', { selectSentry: 'SELECT_SENTRY' }),
    // onClick Methods
    onClickMasks(sensor) {
      return this.$emit('onClickMasks', sensor)
    },
    onClickDroneCalibration(sensor) {
      return this.$emit('onClickDroneCalibration', sensor)
    },
    onClickBracketCalibration(sensor) {
      return this.$emit('onClickBracketCalibration', sensor)
    },
    onClickFilters(sensor) {
      return this.$emit('onClickFilters', sensor)
    },
    onClickEdit(sensorType, sensor) {
      return this.$emit('onClickEdit', { sensorType, sensor })
    },
    onClickDelete(sensorType, sensor) {
      return this.$emit('onClickDelete', { sensorType, sensor })
    },
    getSensorColor(sensor, sensorType) {
      return this.$_statusColor(sensor, sensorType)
    },
    // onChange Methods
    async onSensorStatusChange(state, type, sensor) {
      if (type === 'radars') {
        await this.TOGGLE_RADAR({radar: sensor, siteId: this.activeSiteId})
      } else {
        this.waitForQuery = true
        sensor.active = !sensor.active
        await this.TOGGLE_RF_SENSOR({
          siteId: this.activeSiteId,
          rf_sensor: sensor,
          dsx: type !== 'rf_sensors'
        })
        if (!sensor.active) {
          this.$emitter.emit('clearRfSensorDetections', {
            rf_sensor_id: sensor.id,
            type: type
          })
        }
        this.waitForQuery = false
      }
    },

    onCameraScanChange() {
      this.CAMERA_CONTROL({
        camera_id: this.sensor.id,
        command: this.sensor.scanning ? 'stopScanning' : 'startScanning'
      })
    },

    async onCannonEnableChange(sensor, state) {
      this.jammerLoading = true
      await this.$store.dispatch('cannons/TOGGLE_CANNON', {
        sensor,
        deviceType: sensor?.model?.includes('dsx') ? 'rf_sensor' : 'cannon',
        command: state ? 'turnon' : 'turnoff'
      })
    },

    async onCompassEnabled(compass, state) {
      const updater = Object.assign({}, { ...compass, scopedError: true })
      updater.gps_compass_enabled = state
      delete updater.seconds_since_last_update //server doesn't like this param; TODO: centralize

      const { sentry_id, latitude, longitude } = compass
      try {
        await this.$store.dispatch('sensors/UPDATE_GPS_COMPASS', updater)
        if (state) {
          await this.$store.dispatch('sentries/UPDATE_SENTRY', {
            id: sentry_id,
            latitude,
            longitude
          })
        }
      } catch (err) {
        console.error(err)
      }
    },
    track() {
      if (!['radar', 'rf'].includes(this.detectionType)) {
        return
      }

      const shouldUnTrack =
        this.sensor[`${this.detectionType}_tracked_target`] ===
        this.activeKey ||
        (this.sensor[`${this.detectionType}_tracked_target`] && !this.activeKey)
      const data = {
        siteId: this.activeSiteId,
        cameraId: this.sensor.id,
        [`${this.detectionType}_tracked_target`]: shouldUnTrack
          ? null
          : this.activeKey
      }
      setTimeout(() => this.CAMERA_TRACK_FUSION(data), 200)
    },
    stopTracking() {
      this.STOP_CAMERA_TRACK_FUSION({
        siteId: this.activeSiteId,
        cameraId: this.sensor.id
      })
    },
    watchForLongRadarLoading() {
      let timer = 3
      let mainString = 'Loading'
      this.radarLoadingMessage = `${mainString}...`
      this.longRadarLoadingInterval = setInterval(() => {
        this.radarLoadingMessage = `${mainString}${'.'.repeat(timer++ % 4)}`
        if (timer > 17) {
          mainString = 'Restarting radar'
        } else if (timer > 9) {
          mainString = 'Updating configuration'
        }
      }, 1000)
    },
    rfActiveFilterCountBySensorId(sensorId) {
      return this.rfActiveFilterCount(sensorId)
    }
  },
  watch: {
    jammerActive(v) {
      this.jammerLoading = false
    },
    radarStatusMatch(v) {
      if (!v) this.watchForLongRadarLoading()
      else clearInterval(this.longRadarLoadingInterval)
    },
    tracking: {
      handler: function(val) {
        this.expanded = val
      },
      immediate: true
    }
  },
  mounted() {
    if (this.sensorType === 'cameras') {
      this.$bus.$on('trackTarget', detectionType => {
        this.detectionType = detectionType
        this.track()
      })
    }
    if (!this.radarStatusMatch) this.watchForLongRadarLoading()
  },
  beforeDestroy() {
    if (this.sensorType === 'cameras') {
      this.$bus.$off('trackTarget')
    }
  }
}
</script>
<style scoped>
.bands {
  border: 1px solid orange;
}

.droneoptid {
  padding-right: 3px;
}

.disruptBtn {
  border-color: #ffa725;
}

.disruptBtn:disabled {
  border-color: unset;
}

.disruptBtnActive {
  border-color: #f44336;
}

.droneoptid::before {
  content: '';
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.33, rgb(11, 150, 46)),
    color-stop(0.67, rgb(65, 209, 8))
  );
  border-radius: 100%;
  opacity: 0.5;
  animation: droneoptid 5s linear infinite;
}

@keyframes droneoptid {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotated180 {
  transition: transform 304.513ms cubic-bezier(0.25, 0.8, 0.5, 1) 0ms;
  transform: rotate(180deg);
}

>>> .v-list {
  padding: 0;
}
>>> .v-list__tile {
  font-size: 14px !important;
}

>>> .v-list__tile__action {
  min-width: auto;
  padding-right: 0.5rem;
}
</style>
